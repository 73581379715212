
.qr-scan {
    top: 25px;
  width:100%;
  height: 100%;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 9999;
  margin: auto;
}
.qr-screen {
  width: auto;
  height: auto;
  padding: 25px 0px;
  text-align: center;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 9999;
  margin: auto;
}
