.bg{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 99;
}
.bill-bg {
  position: fixed;
  width: 100%;
  top: 82px;
  bottom: 0px;
  background-image: linear-gradient(#fbba23, #ed5313);
  z-index: 2;
  @media (max-width: 991px) {
    top: 66px;
  }
  @media (max-width: 480px) {
    top: 52px;
  }
  
  .top {
    position: absolute;
    color: #ffffff;
    top: 30%;
    left: 50%;
    font-size: 48px;
    width: auto;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .center {
    position: absolute;
    color: #ffffff;
    top: 50%;
    left: 50%;
    width: auto;
    text-align: center;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .bottom {
    position: absolute;
    color: #ffffff;
    top: 60%;
    left: 50%;
    width: auto;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
