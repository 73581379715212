.product {
  background: $white;
  position: relative;
  margin: 16px;
  width: 200px;
  border-radius: 15px;
  border: #b1b1b1 solid 1px;
  overflow: hidden;
  .product-image {
    overflow: hidden;
    border-radius: 2px 2px 0 0;
    max-height: 200px;
    img {
      cursor: zoom-in;
      width: 100%;
      height: auto;
      transition: transform 300ms ease-in;
      transform: scale(1);
      @media (min-width: 991px) {
        min-height: 200px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .product-name {
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    top: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    height: 40px;
  }
  .product-category {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
  }
  .product-price {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
    display: inline-block;
    &:before {
      content: "฿ ";
    }
  }
  .product-name,
  .product- {
    color: #ffffff;
    padding: 0 16px;
    text-align: center;
  }
  .product-action {
    border-top: 1px solid #dad9d9;
    padding: 15px;
    button {
      width: 100%;
      transition: all 300ms ease-in;
      &.added {
        background: $secondary-orange;
      }
    }
  }
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .product-category {
    padding: 16px;
    button {
      width: 100%;
      transition: all 300ms ease-in;
      &.added {
        background: $secondary-orange;
      }
    }
  }
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.stepper-input {
  display: flex;
  display: -webkit-flex;
  color: $gray-medium;
  max-width: 120px;
  margin: 5px auto;

  .increment,
  .decrement {
    height: 24px;
    width: 24px;
    border: 1px solid $gray-eighty;
    text-align: center;
    box-sizing: border-box;
    border-radius: 50%;
    text-decoration: none;
    color: inherit;
    font-size: 24px;
    line-height: 22px;
    -moz-user-select: none;
    -webkit-user-select: none;
    &:hover {
      color: $primary-orange;
      border-color: $primary-orange;
    }
    &:active {
      color: $white;
      border-color: $primary-orange;
      background: lighten($primary-orange, 15%);
    }
  }
  .quantity {
    height: 24px;
    width: 48px;
    text-align: center;
    margin: 0 12px;
    border-radius: 2px;
    border: 1px solid $gray-eighty;
    &:focus {
      outline: none;
      border-color: $primary-orange;
    }
  }
}
